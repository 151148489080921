import { FC, memo } from 'react';

import Link from 'next/link';

import { useRecoilState } from 'recoil';

import IkukoSectionTitleWrapper from '@components_iqfu/public/ikuko_section_title_wrapper/IkukoSectionTitleWrapper';
import StepperCityCodeSelect from '@components_iqfu/public/stepper_city_code_select/StepperCityCodeSelect';

import {
  searchCompanyAreaPrefectureInputVal,
  searchCompanyAreaCityInputVal,
} from '@recoil_iqfu/atoms/public/searchCompanyArea';

import { CompaniesPageQueryType } from 'src/iqfu/data/companiesPagesData';

import styles from './SearchCompaniesStepper.module.scss';

type Props = {
  pageLabel: string;
  defaultPrefecture?: string;
  defaultCity?: string;
  hasRecommendButton?: boolean;
  location: string;
};

const SearchCompaniesStepper: FC<Props> = memo(
  ({ pageLabel, defaultPrefecture, defaultCity, hasRecommendButton = false, location }) => {
    const [prefectureInputVal, setPrefectureInputVal] = useRecoilState(searchCompanyAreaPrefectureInputVal);
    const [cityInputVal, setCityInputVal] = useRecoilState(searchCompanyAreaCityInputVal);

    const generateTargetPath = (pageQuery: CompaniesPageQueryType) => {
      const targetPath = prefectureInputVal
        ? cityInputVal
          ? `/companies/cities/${cityInputVal}/${pageQuery}`
          : `/companies/prefectures/${prefectureInputVal}/${pageQuery}`
        : `/companies/${pageQuery}`;

      return targetPath;
    };

    return (
      <div>
        <div className={styles.search_companies_sectionTitle_container}>
          <IkukoSectionTitleWrapper>
            <span className="title_text_strong">「強み別」</span>
            <br className={'sp_only'} />
            不動産屋さんランキングを見る
          </IkukoSectionTitleWrapper>
        </div>

        <div className={styles.searchSelectWrap}>
          <div className={styles.city_code_select}>
            <div className={styles.process_label}>
              <p>
                <span className={styles.step}>STEP1</span>
                <span>地域を選択</span>
              </p>
            </div>
            <StepperCityCodeSelect defaultPrefecture={defaultPrefecture} defaultCity={defaultCity} />
          </div>
          <div className={styles.arrow_section}>
            <img
              src={`/images/iqfu/public/top/search_category_arrow.svg`}
              className={styles.arrow_icon}
              alt=""
              loading="lazy"
            />
          </div>

          <div>
            <div className={styles.process_label}>
              <p>
                <span className={styles.step}>STEP2</span>
                <span>不動産会社の強みを選択</span>
              </p>
            </div>
            <div className={styles.category_buttons}>
              <Link
                className={styles.searchSelect}
                data-gtm-click={`SearchCompaniesStepper_mediation_company_search_${location}`}
                href={generateTargetPath('')}
              >
                <span className={styles.icon_image}>
                  <img
                    src="/images/iqfu/public/search_companies_stepper/sale_case_count_icon.svg"
                    alt=""
                    loading="lazy"
                  />
                </span>
                <div className={styles.button_text}>
                  地元で
                  <br />
                  売却に強い
                </div>
              </Link>
              <Link
                className={styles.searchSelect}
                data-gtm-click={`SearchCompaniesStepper_land_company_search_${location}`}
                href={generateTargetPath('land')}
              >
                <span className={styles.icon_image}>
                  <img src="/images/iqfu/public/search_companies_stepper/land_icon.svg" alt="" loading="lazy" />
                </span>
                <div className={styles.button_text}>
                  土地の
                  <br />
                  売却に強い
                </div>
              </Link>
              <Link
                className={styles.searchSelect}
                data-gtm-click={`SearchCompaniesStepper_house_company_search_${location}`}
                href={generateTargetPath('house')}
              >
                <span className={styles.icon_image}>
                  <img src="/images/iqfu/public/search_companies_stepper/house_icon.svg" alt="" loading="lazy" />
                </span>
                <div className={styles.button_text}>
                  一戸建ての
                  <br />
                  売却に強い
                </div>
              </Link>
              <Link
                className={styles.searchSelect}
                data-gtm-click={`SearchCompaniesStepper_mansion_company_search_${location}`}
                href={generateTargetPath('mansion')}
              >
                <span className={styles.icon_image}>
                  <img src="/images/iqfu/public/search_companies_stepper/mansion_icon.svg" alt="" loading="lazy" />
                </span>
                <div className={styles.button_text}>
                  マンションの
                  <br />
                  売却に強い
                </div>
              </Link>
              <Link
                className={styles.searchSelect}
                data-gtm-click={`SearchCompaniesStepper_sell_high_company_search_${location}`}
                href={generateTargetPath('sell_high')}
              >
                <span className={styles.icon_image}>
                  <img src="/images/iqfu/public/search_companies_stepper/sell_high_icon.svg" alt="" loading="lazy" />
                </span>
                <div className={styles.button_text}>
                  高く売る
                  <br />
                  のが得意な
                </div>
              </Link>
              <Link
                className={styles.searchSelect}
                data-gtm-click={`SearchCompaniesStepper_sell_fast_company_search_${location}`}
                href={generateTargetPath('sell_fast')}
                prefetch={false}
              >
                <span className={styles.icon_image}>
                  <img src="/images/iqfu/public/search_companies_stepper/sell_fast_icon.svg" alt="" loading="lazy" />
                </span>
                <div className={styles.button_text}>
                  早く売る
                  <br />
                  のが得意な
                </div>
              </Link>
              <Link
                className={styles.searchSelect}
                data-gtm-click={`SearchCompaniesStepper_not_decline_company_search_${location}`}
                href={generateTargetPath('any_properties')}
                prefetch={false}
              >
                <span className={styles.icon_image}>
                  <img src="/images/iqfu/public/search_companies_stepper/not_decline_icon.svg" alt="" loading="lazy" />
                </span>
                <div className={styles.button_text}>
                  どんな物件
                  <br />
                  も断らない
                </div>
              </Link>
              <Link
                className={styles.searchSelect}
                data-gtm-click={`SearchCompaniesStepper_purchase_company_search_${location}`}
                href={generateTargetPath('purchase')}
              >
                <span className={styles.icon_image}>
                  <img src="/images/iqfu/public/search_companies_stepper/purchase_icon.svg" alt="" loading="lazy" />
                </span>
                <div className={`${styles.button_text} pc_only`}>買取が得意な</div>
                <div className={`${styles.button_text} sp_only`}>
                  買取が
                  <br />
                  得意な
                </div>
              </Link>
              <Link
                className={styles.searchSelect}
                data-gtm-click={`SearchCompaniesStepper_divorce_company_search_${location}`}
                href={generateTargetPath('divorce')}
                prefetch={false}
              >
                <span className={styles.icon_image}>
                  <img src="/images/iqfu/public/search_companies_stepper/divorce_icon.svg" alt="" loading="lazy" />
                </span>
                <div className={`${styles.button_text}`}>
                  離婚による
                  <br />
                  売却が得意な
                </div>
              </Link>
              <Link
                className={styles.searchSelect}
                data-gtm-click={`SearchCompaniesStepper_inheritance_company_search_${location}`}
                href={generateTargetPath('inheritance')}
                prefetch={false}
              >
                <span className={styles.icon_image}>
                  <img src="/images/iqfu/public/search_companies_stepper/inheritance_icon.svg" alt="" loading="lazy" />
                </span>
                <div className={`${styles.button_text}`}>
                  相続による
                  <br />
                  売却が得意な
                </div>
              </Link>
              <Link
                className={styles.searchSelect}
                data-gtm-click={`SearchCompaniesStepper_leaseback_company_search_${location}`}
                href={generateTargetPath('leaseback')}
                prefetch={false}
              >
                <span className={styles.icon_image}>
                  <img src="/images/iqfu/public/search_companies_stepper/leaseback_icon.svg" alt="" loading="lazy" />
                </span>
                <div className={`${styles.button_text}`}>
                  リースバック
                  <br />
                  に対応できる
                </div>
              </Link>
            </div>
          </div>
        </div>
        {hasRecommendButton && (
          <div className={styles.search_recommend_area}>
            <div className={styles.recommend_button}>
              <Link
                className={styles.local_company_search}
                data-gtm-click={`SearchCompaniesStepper_recommend_company_search_${location}`}
                href={generateTargetPath('')}
              >
                <div className={styles.local_company_search_inner}>
                  <img
                    src={`/images/iqfu/public/search_companies_stepper/ikuko_search.svg`}
                    className={styles.recommend_ikuko_icon}
                    alt=""
                    loading="lazy"
                  />
                  <span>イクラ不動産おすすめ！</span>
                </div>
              </Link>
            </div>
            <div className={styles.recommend_label}>
              <span>不動産屋さんランキングを見る!!</span>
            </div>
          </div>
        )}
      </div>
    );
  },
);

SearchCompaniesStepper.displayName = 'SearchCompaniesStepper';

export default SearchCompaniesStepper;
