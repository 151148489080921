import React, { FC, useEffect, useState } from 'react';

import { FormControl, Select, MenuItem, useMediaQuery } from '@mui/material';

import { useQuery } from 'react-query';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { useAddress } from '@hooks_common/useAddress';

import {
  searchCompanyAreaPrefectureInputVal,
  searchCompanyAreaCityInputVal,
} from '@recoil_iqfu/atoms/public/searchCompanyArea';

import { SelectableArray } from '@type-def_common/SelectableArray';

import { MediaQuery } from '@constants_common';
import { Prefectures } from '@constants_iqfu';

import styles from './StepperCityCodeSelect.module.scss';

type Props = {
  defaultPrefecture?: string;
  defaultCity?: string;
};

const StepperCityCodeSelect: FC<Props> = ({ defaultPrefecture, defaultCity }) => {
  const { getCities } = useAddress();
  const [selectableCities, setSelectableCities] = useState<SelectableArray>({});
  const [prefectureInputVal, setPrefectureInputVal] = useRecoilState(searchCompanyAreaPrefectureInputVal);
  const [cityInputVal, setCityInputVal] = useRecoilState(searchCompanyAreaCityInputVal);
  const isMobile = useMediaQuery(MediaQuery.lg);

  const { data: citiesInPrefectures } = useQuery(
    ['citiesInPrefectures', prefectureInputVal, defaultPrefecture, defaultCity],
    () => getCities(prefectureInputVal as string, true),
    {
      enabled: !!prefectureInputVal,
      staleTime: 1000 * 60,
    },
  );

  useEffect(() => {
    const cities = citiesInPrefectures?.cities;
    let tempSelectableCities: SelectableArray = {};
    tempSelectableCities = {};

    if (cities) {
      cities.map((city: { code: number; name: string }) => {
        tempSelectableCities[city.code] = city.name;
      });
      setSelectableCities(tempSelectableCities);
    }
  }, [citiesInPrefectures]);

  const handlePrefectureChange = async (selectedNumber: string | number): Promise<void> => {
    setCityInputVal('');
    setPrefectureInputVal(String(selectedNumber));
  };

  const handleCityChange = async (selectedNumber: string | number): Promise<void> => {
    setCityInputVal(String(selectedNumber));
  };

  useEffect(() => {
    (async () => {
      if (defaultPrefecture) {
        await handlePrefectureChange(defaultPrefecture);
        if (defaultCity) {
          handleCityChange(defaultCity);
        }
      }
    })();
  }, [defaultPrefecture, defaultCity]);

  useEffect(() => {
    //sessionStorageに保存しているが、リロード時には値をクリアする
    window.addEventListener('beforeunload', (e) => clearRecoil(e));
    return () => {
      window.removeEventListener('beforeunload', (e) => clearRecoil(e));
    };
  }, []);

  const resetPref = useResetRecoilState(searchCompanyAreaPrefectureInputVal);
  const resetCity = useResetRecoilState(searchCompanyAreaCityInputVal);

  const clearRecoil = (e: BeforeUnloadEvent) => {
    resetPref();
    resetCity();
  };

  const disabledClassName = Object.keys(selectableCities).length > 0 ? '' : styles.disabled;

  return (
    <div className={styles.container}>
      <FormControl>
        {!isMobile ? (
          <Select
            placeholder="都道府県を選択"
            displayEmpty
            value={prefectureInputVal}
            onChange={(event) => {
              handlePrefectureChange(event.target.value);
            }}
            renderValue={(value) =>
              value ? Prefectures[Number(value)] : <span className={styles.placeholder}>都道府県を選択</span>
            }
            className={`${styles.select}`}
            MenuProps={{
              classes: {
                root: styles.menu_root,
                paper: styles.menu_paper,
              },
            }}
          >
            {Object.keys(Prefectures).map((key) => (
              <MenuItem className={styles.menu_item} key={key} value={key}>
                {Prefectures[Number(key)]}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <div className={styles.mobile_native_container}>
            <svg
              className={styles.mobile_native_icon}
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              data-testid="ArrowDropDownIcon"
            >
              <path d="M7 10l5 5 5-5z"></path>
            </svg>
            <select
              className={styles.mobile_native_select}
              value={prefectureInputVal}
              onChange={(event) => {
                handlePrefectureChange(event.target.value);
              }}
            >
              <option value="" disabled selected hidden>
                都道府県を選択
              </option>

              {Object.keys(Prefectures).map((key) => (
                <option value={key} key={key}>
                  {Prefectures[Number(key)]}
                </option>
              ))}
            </select>
          </div>
        )}
      </FormControl>

      <FormControl>
        {!isMobile ? (
          <Select
            placeholder="市区町村を選択"
            displayEmpty
            value={cityInputVal}
            onChange={(event) => {
              handleCityChange(event.target.value);
            }}
            renderValue={(value) =>
              value ? selectableCities[Number(value)] : <span className={styles.placeholder}>市区町村を選択</span>
            }
            className={`${styles.select} ${disabledClassName}`}
            MenuProps={{
              classes: {
                root: styles.menu_root,
                paper: styles.menu_paper,
              },
            }}
          >
            {Object.keys(selectableCities).map((key) => (
              <MenuItem className={styles.menu_item} key={key} value={key}>
                {selectableCities[Number(key)]}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <div className={styles.mobile_native_container}>
            <svg
              className={styles.mobile_native_icon}
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              data-testid="ArrowDropDownIcon"
            >
              <path d="M7 10l5 5 5-5z"></path>
            </svg>
            <select
              className={`${styles.mobile_native_select} ${disabledClassName}`}
              value={cityInputVal}
              onChange={(event) => {
                handleCityChange(event.target.value);
              }}
            >
              <option value="" disabled selected hidden>
                市区町村を選択
              </option>

              {Object.keys(selectableCities).map((key) => (
                <option value={key} key={key}>
                  {selectableCities[Number(key)]}
                </option>
              ))}
            </select>
          </div>
        )}
      </FormControl>
    </div>
  );
};

export default StepperCityCodeSelect;
