import { atom } from 'recoil';

export const searchCompanyAreaPrefectureInputVal = atom<string>({
  key: 'searchCompanyAreaPrefectureInputVal',
  default: '',
});

export const searchCompanyAreaCityInputVal = atom<string>({
  key: 'searchCompanyAreaCityInputVal',
  default: '',
});
