import * as Sentry from '@sentry/browser';
import { useCookies } from 'react-cookie';

const backendBasePath = process.env.NEXT_PUBLIC_DEV_BACKEND_URL;

export const useAddress = () => {
  const [cookies] = useCookies(['expiry', 'access-token', 'client', 'uid', 'user-type']);

  const getCities = async (prefectureCode: string, withMajorCity?: boolean) => {
    if (!prefectureCode || prefectureCode === 'undefined') return [];
    const url = `${backendBasePath}/prefectures/${prefectureCode}/cities${
      withMajorCity ? '?with_major_city=true' : '?with_major_city=false'
    }`;

    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })

      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });

    return result;
  };

  const getTowns = async (cityID: string): Promise<{ towns: { code: string; name: string }[] }> => {
    if (!cityID || cityID === 'undefined') return { towns: [] };
    const url = `${backendBasePath}/cities/${cityID}/towns`;

    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })

      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });

    if (result) {
      return result;
    } else {
      return { towns: [] };
    }
  };

  const getCityIncrementalOptions = async (query: string) => {
    const url = `${backendBasePath}/cities/options?q=${query}`;

    const result = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        uid: cookies['uid'],
        client: cookies['client'],
        'access-token': cookies['access-token'],
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('通信に失敗しました', error);
      });

    return result;
  };

  return {
    getCities,
    getTowns,
    getCityIncrementalOptions,
  };
};
